<h1 class="title" [ngClass]="{'is-loading': loading}" grtSubtitle>
    Select Treebanks
</h1>
<div class="columns is-desktop">
    <div class="column">
        <p>
            Which treebank do you want to query? Click on the treebank name to see its different components. If you
            would
            like to get more information on these treebanks, you can find the project websites in
            <a [routerLink]="['/','documentation']" fragment="about-corpora" target="_blank" grtBalloonPosition="down"
                grtBalloon="More information on corpora">our FAQ</a>.
        </p>
    </div>
    <div class="column">
        <p>You can search an entire treebank, or select just one or more components. Due to pre-processing
            difficulties some sentences could not be included in the system, so the sentence and word counts may
            slightly differ from
            the official treebank counts.
        </p>
    </div>
</div>
<div class="level">
    <div class="level-left">
        <div class="field has-addons">
            <p class="control">
                <input class="input" type="search" placeholder="Filter by name" [(ngModel)]="filterText" />
            </p>
            <div class="control">
                <grt-select-treebank-providers [(preConfigured)]="showPreConfigured" [users]="userProviders"
                    [(selectedUserIds)]="showUsers" (showUserTags)="showUserTags = true">
                </grt-select-treebank-providers>
            </div>
            <p class="control" *ngIf="userProviders.length">
                <button class="button" [ngClass]="{'is-primary': showUserTags }" (click)="showUserTags=!showUserTags"
                    grtBalloon="Show the treebanks' users">
                    <span class="icon">
                        <fa-icon [icon]="faTags" aria-hidden="true"></fa-icon>
                    </span>
                </button>
            </p>
        </div>
    </div>
    <div class="level-right is-hidden-print">
        <div class="level-item">
            <div class="field">
                <p class="control" (click)="prev.emit()">
                    <a class="button is-secondary is-hidden-tablet" role="button" title="Previous">
                        <span class="icon ">
                            <fa-icon [icon]="faChevronLeft" aria-hidden="true"></fa-icon>
                        </span>
                    </a>
                    <a class="button is-secondary is-hidden-mobile" role="button">
                        <span class="is-hidden-mobile">Previous</span>
                    </a>
                </p>
            </div>
        </div>
        <div class="level-item">
            <div class="field">
                <p class="control" (click)="next.emit()">
                    <a class="button is-secondary is-hidden-tablet" role="button" title="Previous">
                        <span class="icon">
                            <fa-icon [icon]="faChevronRight" aria-hidden="true"></fa-icon>
                        </span>
                    </a>
                    <a class="button is-primary is-hidden-mobile" role="button">
                        <span class="is-hidden-mobile">Next</span>
                    </a>
                </p>
            </div>
        </div>
    </div>
</div>
<ng-container *ngFor="let treebank of treebanks">
    <ng-container *ngIf="treebank.selected ||
        (((treebank.displayName | highlightMatch:filterText) || (treebank.description | highlightMatch:filterText) || (showUserTags && (treebank.userName | highlightMatch:filterText))) &&
         ((showPreConfigured && treebank.preConfigured) ||
          (showUsers.indexOf(treebank.userId) >= 0)))">
        <div class="field">
            <label class="checkbox">
                <input type="checkbox" [value]="treebank.id" [checked]="treebank.selected"
                    (change)="toggleTreebank(treebank.provider, treebank.id)">
                <span [innerHtml]="' ' + treebank.displayName | highlight:filterText"></span>
                <ng-container *ngIf="treebank.description">: <em
                        [innerHtml]="treebank.description | highlight:filterText"></em>
                </ng-container>
                <ng-container *ngIf="!treebank.isPublic">
                    &nbsp;
                    <span class="tag is-dark">
                        <span class="icon">
                            <fa-icon [icon]="faLock" aria-hidden="true"></fa-icon>
                        </span>
                        &nbsp;
                        private
                    </span>
                </ng-container>
                <ng-container *ngIf="treebank.color">
                    &nbsp;
                    <div class="tag is-{{treebank.color}}" [ngClass]="{'is-invisible': !showUserTags}"
                        [innerHtml]="treebank.userName | highlight:filterText"></div>
                </ng-container>
            </label>
        </div>
        <div *ngIf="treebank.selected" class="box-is-shadowless" [@slideDown]>
            <grt-sub-treebanks [treebank]="treebank"></grt-sub-treebanks>
        </div>
    </ng-container>
</ng-container>
