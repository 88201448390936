<div class="level">
    <div class="level-left">
        <div class="level is-mobile">
            <div class="level-item" *ngIf="filters.length">
                <div class="field">
                    <p class="control">
                        <a class="button" role="button" (click)="hideFiltersColumn = !hideFiltersColumn"
                            [ngClass]="{'is-secondary': hideFiltersColumn, 'has-badge-rounded': activeFilterCount}" [attr.data-badge]="activeFilterCount ? activeFilterCount : undefined"
                            title="Toggle filters">
                            <span class="icon">
                                <fa-icon [icon]="faBars" aria-hidden="true"></fa-icon>
                            </span>
                        </a>
                    </p>
                </div>
            </div>
            <div class="level-item">
                <h1 class="title" [ngClass]="{'is-loading': loading}" grtSubtitle>
                    Results: {{filteredResults.length - hiddenCount | transitionNumbers}}<ng-container *ngIf="hiddenCount > 0"> ({{hiddenCount | transitionNumbers}}
                        hidden)
                    </ng-container>
                </h1>
            </div>
        </div>
    </div>
    <div class="level-right is-hidden-print">
        <div class="level is-mobile">
            <div class="level-item">
                <div class="field">
                    <p class="control" (click)="prev.emit()">
                        <a class="button is-secondary is-hidden-tablet" role="button" title="Previous">
                            <span class="icon ">
                                <fa-icon [icon]="faChevronLeft" aria-hidden="true"></fa-icon>
                            </span>
                        </a>
                        <a class="button is-secondary is-hidden-mobile" role="button">
                            <span class="is-hidden-mobile">Previous</span>
                        </a>
                    </p>
                </div>
            </div>
            <div class="level-item">
                <div class="field has-addons">
                    <p class="control">
                        <grt-download-results [loading]="loadingDownload" (downloadResults)="downloadResults.next($event)"></grt-download-results>
                    </p>
                    <p class="control" *ngIf="!loading">
                        <a class="button" role="button" (click)="downloadFilelist.next()" grtBalloonLength="large"
                            grtBalloonPosition="down" grtBalloon="Download the names of the files in a filelist (.fl)">
                            <span class="icon is-large">
                                <fa-icon [icon]="faFileAlt" aria-hidden="true"></fa-icon>
                            </span>
                        </a>
                    </p>
                </div>
            </div>
            <div class="level-item">
                <div class="field">
                    <p class="control" grtBalloonPosition="down" grtBalloon="Print Results"
                        [grtBalloonVisible]="loading ? false : undefined">
                        <a class="button" role="button" [ngClass]="{'is-loading':loading}" (click)="print()">
                            <span class="icon is-large">
                                <fa-icon [icon]="faPrint" aria-hidden="true"></fa-icon>
                            </span>
                        </a>
                    </p>
                </div>
            </div>
            <div class="level-item">
                <div class="field">
                    <p class="control" (click)="toggleContext.next()">
                        <a class="button is-warning" role="button" grtBalloonLength="large" grtBalloonPosition="down"
                            grtBalloon="Click to show the sentences directly before and after the hits."
                            *ngIf="!retrieveContext">
                            <span class="icon is-large">
                                <fa-icon [icon]="faCommentSlash" aria-hidden="true"></fa-icon>
                            </span>
                        </a>
                        <a class="button is-primary" role="button" grtBalloonLength="large" grtBalloonPosition="down"
                            grtBalloon="Click to hide the sentences surrounding the hits." *ngIf="retrieveContext">
                            <span class="icon is-large">
                                <fa-icon [icon]="faCommentDots" aria-hidden="true"></fa-icon>
                            </span>
                        </a>
                    </p>
                </div>
            </div>
            <div class="level-item">
                <div class="field">
                    <p class="control" (click)="next.emit()">
                        <a class="button is-secondary is-hidden-tablet" role="button" title="Previous">
                            <span class="icon ">
                                <fa-icon [icon]="faChevronRight" aria-hidden="true"></fa-icon>
                            </span>
                        </a>
                        <a class="button is-primary is-hidden-mobile" role="button">
                            <span class="is-hidden-mobile">Next</span>
                        </a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<grt-filters-by-xpath [filters]="filterXPaths" (delete)="deleteFilter.next($event)"></grt-filters-by-xpath>
<div class="columns">
    <div class="column is-one-third is-hidden-print" *ngIf="filters.length" [@fade]
        [ngClass]="{'is-hidden': hideFiltersColumn }">
        <div class="filter">
            <grt-filters [filters]="filters" [filterValues]="filterValues" (filterChange)="filterChange($event)">
            </grt-filters>
        </div>
        <br />
        <div class="buttons is-centered">
            <a class="button is-primary" role="button" (click)="hideFiltersColumn = true">
                Hide Filters
            </a>
        </div>
    </div>
    <div class="column">
        <p-table *ngIf="filteredResults.length > hiddenCount else warning" [columns]="columns" [value]="filteredResults"
            [resizableColumns]="true" responsiveLayout="stack" styleClass="bulma">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of columns" pResizableColumn [style.width]="col.width">
                        {{col.header}}
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns" let-i="rowIndex">
                <tr *ngIf="!rowData.hidden" [@fade]>
                    <td *ngFor="let col of columns" class="p-resizable-column">
                        <span *ngIf="col.field !== 'highlightedSentence'" class="p-column-title">{{col.header}}</span>
                        <ng-container [ngSwitch]="col.field">
                            <span *ngSwitchCase="'number'">{{i + 1}}</span>

                            <ng-container *ngSwitchCase="'fileId'">
                                <a *ngIf="rowData[col.field]; else fileIdButton"
                                    (click)="showTree.emit(rowData)">{{rowData[col.field].split('/').pop()}}</a>
                                <ng-template #fileIdButton>
                                    <button type="button" class="button" (click)="showTree.emit(rowData)">Show tree</button>
                                </ng-template>
                            </ng-container>

                            <ng-container *ngSwitchCase="'highlightedSentence'">
                                <span *ngIf="retrieveContext" class="content is-small">{{rowData['previousSentence']}} </span>
                                <span [innerHTML]="rowData['highlightedSentence']"></span>
                                <span *ngIf="retrieveContext" class="content is-small"> {{rowData['nextSentence']}}</span>
                            </ng-container>

                            <span [innerHTML]="rowData[col.field]" *ngSwitchDefault></span>
                        </ng-container>
                    </td>
                </tr>
            </ng-template>
        </p-table>

        <ng-template #warning>
            <div [@slideDown] *ngIf="!loading && hiddenCount === 0" class="notification is-warning">
                <ng-container *ngIf="activeFilterCount > 0; else noActiveFilters">No results found, please try again
                    with another treebank, other xpath-query or change the filters.</ng-container>
                <ng-template #noActiveFilters>No results found, please try again with another treebank or other
                    xpath-query.</ng-template>
            </div>
            <div [@slideDown] *ngIf="hiddenCount > 0 && hiddenCount === filteredResults.length" class="notification">
                All results are hidden.
            </div>
        </ng-template>
    </div>
</div>
