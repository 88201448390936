<div class="dropdown" [ngClass]="{'is-active': dropdownActive}">
    <div class="dropdown-trigger">
        <button class="button" aria-haspopup="true" aria-controls="dropdown-menu"
                (click)="dropdownActive = !dropdownActive">
            <span>{{ currentQuery.rank }}: {{ currentQuery.description }}</span>
            <span class="icon is-small">
                <fa-icon [icon]="faAngleDown" aria-hidden="true"></fa-icon>
            </span>
        </button>
    </div>
    <div class="dropdown-menu" id="dropdown-menu" role="menu">
        <div class="dropdown-content">
            <a *ngFor="let query of querySet" class="dropdown-item"
               (click)="menuSelected(query)">
                {{ query.rank }}: {{ query.description }}
            </a>
        </div>
    </div>
</div>
