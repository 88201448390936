 <h2 class="subtitle">
    <div class="level">
        <div class="level-item level-left">
            Components
        </div>
        <div class="level-item level-right is-hidden-print">
            <div class="field">
                <p class="control" grtBalloonPosition="down-right" grtBalloon="Download distribution list (.csv)">
                    <a class="button" [ngClass]="{'is-loading': loading}" (click)="download()">
                        <span class="icon is-large">
                            <fa-icon [icon]="faDownload" aria-hidden="true"></fa-icon>
                        </span>
                    </a>
                </p>
            </div>
        </div>
    </div>
</h2>
<table class="table is-fullwidth scroll has-text-left">
    <thead #thead>
        <tr>
            <th scope="col">
                <!-- disabled checkbox to align precisely with the other checkboxes-->
                <label class="checkbox">
                    <input type="checkbox" style="visibility: hidden" disabled />
                </label>
                Name
            </th>
            <th scope="col" class="has-text-right" #padCell>Hits</th>
            <th scope="col" class="has-text-right" #padCell>All Sentences</th>
        </tr>
    </thead>
    <tbody #tbody>
        <ng-container *ngFor="let provider of state | keyvalue; trackBy: trackByKey">
            <ng-container *ngFor="let corpus of provider.value | keyvalue; trackBy: trackByKey">
                <tr>
                    <th scope="row">
                        <label class="checkbox">
                            <input
                                type="checkbox"
                                [checked]="!corpus.value.hidden"
                                (change)="toggleAllComponents(provider.key, corpus.key, !corpus.value.hidden)"
                            /> {{corpus.key}}
                        </label>
                    </th>
                    <th class="has-text-right">
                        <span class="icon is-loading" *ngIf="corpus.value.loading"></span>
                        {{corpus.value.hits | transitionNumbers}}
                    </th>
                    <th class="has-text-right">{{corpus.value.sentenceCount}}</th>
                </tr>
                <ng-container *ngIf="!corpus.value.hidden">
                    <tr *ngFor="let component of corpus.value.components | keyvalue; trackBy: trackByKey">
                        <td>
                            &nbsp; &nbsp; <label class="checkbox">
                                <input
                                    type="checkbox"
                                    [checked]="!component.value.hidden"
                                    (change)="toggleComponent(provider.key, corpus.key, component.key, !component.value.hidden)"
                                /> {{component.value.title}}
                            </label>
                        </td>
                        <td class="has-text-right">
                            <span class="icon is-loading" *ngIf="component.value.loading"></span>
                            <ng-container *ngIf="component.value.hits !== undefined">{{component.value.hits | transitionNumbers}}</ng-container>
                        </td>
                        <td class="has-text-right">{{component.value.sentenceCount}}</td>
                    </tr>
                </ng-container>
                <tr></tr>
            </ng-container>
        </ng-container>
    </tbody>
    <tfoot>
        <tr>
            <td></td>
            <td class="has-text-right" #padCell>
                <span class="icon is-loading" *ngIf="loading"></span>
                {{totalHits | transitionNumbers}}
            </td>
            <td class="has-text-right" #padCell>{{totalSentences}}</td>
        </tr>
    </tfoot>
</table>
