<div class="content">
    <p>
        GrETEL is a web application that enables researchers to search for grammatical constructions in text corpora
        based
        on an example of the construction. This makes it possible for a user to search for grammatical constructions
        without
        having to know the full details of the syntactic structures used in GrETEL or a query language.
    </p>
    <p>
        Versions 1 through 3 of GrETEL were created by KU Leuven [Augustinus et al. 2012], Utrecht University´s Centre
        for
        Digital Humanities (<a href="https://cdh.uu.nl/about/research-software-lab/">Research Software Lab</a>) has
        continued the development with versions 4 (<a href="https://gretel.hum.uu.nl">gretel.hum.uu.nl</a>) and 5 (<a
            href="https://gretel5.hum.uu.nl">gretel5.hum.uu.nl</a>). In version 4 the UU Centre for Digital Humanities
        added
        a component to analyse the search results and a component for users to upload their own text corpus [Odijk et
        al.
        2018]. In version 5, which is still under development, a component has been added to enable search for flexible
        multiword expressions based on an example. [Odijk et al., to appear]. Further works on GreTEL include
        [Augustinus et
        al. 2017, Broeder & Odijk 2022, Odijk 2020a, Odijk 2022b, Odijk 2023]
    </p>
    <p>
        GrETEL has been used for a wide variety of linguistic research, resulting in multiple Ph.D. theses and
        scientific
        articles. These include [Augustinus & van Eynde 2012, 2015, 2017], [Augustinus 2015], [Bloem 2021], [Van Tuyl &
        Coopmans 2021], [Odijk 2015, 2016, 2020b, 2022a], [Van Eynde 2014], [Van Eynde et al. 2016]. And it is currently
        being used by a range of researchers, including Martin Kroon (UL, UU), Marijana Marelj (UU), F. Groothoff (UU),
        Floris Roelofsen (UvA), and the applicant, Aleksei Nazarov. It has also been used to enrich the Dutch
        Taalportaal
        with links to data in actual corpora [Bouma et al. 2015, Van der Wouden 2016, 2017]
    </p>
    <p>
        An educational package for GrETEL was developed in 2014 [Augustinus et al, 2014], and more recently a tutorial
        was
        given on GrETEL 4. This tutorial was recorded and is online available, together with the tutorial materials
        [Odijk
        2022c].
    </p>
    <p>
        The development of GrETEL at UU has also given rise to a spin-off application called SASTA (Semi-Automatic
        Annotation of Spontaneous Language), which enables the automatic analysis of spontaneous language transcripts of
        young children with suspected language development delays or disorders or of patients with aphasia in accordance
        with a variety of established methods in this domain. [Odijk 2021].
    </p>
    <p>
        The GrETEL web application is hosted on servers of the UU DH-LAB, but also on the servers of the recognized
        CLARIN
        Type B Centre Instituut voor de Nederlandse Taal in order to ensure long term availability of this application
        to
        the research community. (<a href="https://gretel.ivdnt.org" target="_blank">gretel.ivdnt.org</a>)
    </p>
</div>
