<h2 class="subtitle" grtSubtitle>
    {{ title }}
</h2>

<form>
    <div class="field has-addons">
        <div class="control is-expanded">
            <input type="text" class="input"
                   autocomplete="off"
                   placeholder="Start typing an expression, or a list of words" spellcheck="true" lang="nl"
                   name="filterString"
                   [(ngModel)]="filterString">
        </div>
    </div>
</form>

<div *ngIf="count() === 0" class="block">
    <p class="mt-3">The query "{{ filterString }}" does not match a known multiword expression.</p>
    <p class="mt-3">To search for <strong>{{ filterString }}</strong> as an expression,
        <a (click)="onSelect.emit({text: filterString})">click here</a>.
    </p>
</div>


<div *ngIf="count() > 0">
    <div class="block mt-3">
        Showing {{ visible() | transitionNumbers:true }} out of {{ count() | transitionNumbers:true }} matching known expressions:
    </div>
    <table class="table is-fullwidth">
        <tr *ngFor="let sent of sentences"
            class="result"
            (click)="onSelect.emit(sent)">
            <td>{{sent.text}}</td>
        </tr>
        <tr *ngIf="filterString.length > 3" class="result">
            <td (click)="onSelect.emit({text: filterString})">Click here to search for "{{ filterString }}"</td>
        </tr>
    </table>

    <a (click)="showMore()">Show more...</a>
</div>
